'use client'

import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { ShapeDotted } from '@/components/Svg/ShapeDotted'
import { ContactSectionProps } from '@/util/payload/types'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import 'react-phone-input-2/lib/style.css'
import { QuestionForm } from './QuestionForm'

export interface FormContactUs {
  name?: string
  email?: string
  phone?: string
  company?: string
  industry?: string
  challenges?: string
  goals?: string
  sales?: string
  leads?: string
  sent?: boolean
}

export const ContactSectionBlock: FunctionComponent<ContactSectionProps> = ({ email, location, phone, type, formPosition }) => {
  const [message, setMessage] = React.useState('')
  const [error, setError] = React.useState('')

  const [state, setState] = React.useState<FormContactUs>({})
  const handleChange = (key: keyof FormContactUs, value: string) => {
    setError('')
    setState({ ...state, [key]: value })
  }

  return (
    <section id="support" className="px-4 md:px-8 2xl:px-0">
      <div className="relative mx-auto max-w-c-1390 px-7.5 pt-10 lg:px-15 lg:pt-15 xl:px-20 xl:pt-20">
        <div className="absolute left-0 top-0 -z-1 h-2/3 w-full rounded-lg bg-gradient-to-t from-[#fff] to-[#dee7ff47] dark:bg-gradient-to-t dark:from-transparent dark:to-[#24283E]"></div>
        <div className="absolute bottom-[-255px] left-0 -z-1 h-full w-full">
          <ShapeDotted />
        </div>

        <div className={clsx(
          'flex flex-col-reverse flex-wrap gap-8 md:flex-row md:flex-nowrap md:justify-between xl:gap-20',
          formPosition === 'right' ? 'md:flex-row-reverse' : ''
        )}>
          <MotionDiv preset='fade-up' className={clsx('w-full rounded-lg bg-white p-7.5 shadow-solid-8 dark:border dark:border-strokedark dark:bg-black xl:p-15', location && email && phone ? 'md:w-3/5 lg:w-3/4' : '')}>
            <h2 className="mb-15 text-3xl font-semibold text-black dark:text-white xl:text-sectiontitle2">
              Apply Now
            </h2>
            <form onSubmit={(e) => {
              e.preventDefault()
              const { email: _email, name, company } = state
              if (!name) {
                setError('Name is missing')
                return
              }
              if (!_email) {
                setError('Email is missing')
                return
              }
              if (!company && type === 'standard') {
                setError('Company is missing')
                return
              }
              fetch('/api/form', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(state)
              })
                .then((res) => {
                  setMessage('Thank you, we will contact you shortly.')
                  setState({ ...state, sent: true })
                  setTimeout(() => {
                    setState({
                      challenges: '',
                      company: '',
                      email: '',
                      name: '',
                      goals: '',
                      industry: '',
                      phone: '',
                      leads: '',
                      sales: ''
                    })
                    setMessage('')
                  }, 5000)
                })
            }}>
              <QuestionForm error={error} message={message} handleChange={handleChange} state={state} type={type ?? 'standard'} />
              {!state.sent && (
                <div className="flex flex-wrap gap-4 xl:justify-between ">
                  <button
                    aria-label="send message"
                    className="inline-flex items-center gap-2.5 rounded-full bg-black px-6 py-3 font-medium text-white duration-300 ease-in-out hover:bg-blackho dark:bg-btndark"
                    type='submit'>
                      Apply Now
                    <svg
                      className="fill-white"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z"
                        fill=""
                      />
                    </svg>
                  </button>
                </div>
              )}
            </form>
          </MotionDiv>

          {location && email && phone && (
            <MotionDiv preset='fade-up' className="w-full md:w-2/5 md:p-7.5 lg:w-[26%] xl:pt-15">
              <h3 className="mb-12.5 text-3xl font-semibold text-black dark:text-white xl:text-sectiontitle2">
                Find us
              </h3>

              <div className="5 mb-7">
                <h4 className="mb-4 text-metatitle3 font-medium text-black dark:text-white">
                  Our Location
                </h4>
                <p>{location}</p>
              </div>
              <div className="5 mb-7">
                <h4 className="mb-4 text-metatitle3 font-medium text-black dark:text-white">
                  Email Address
                </h4>
                <p>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
              <div>
                <h4 className="mb-4 text-metatitle3 font-medium text-black dark:text-white">
                  Phone Number
                </h4>
                <p>
                  <a href="#">{phone}</a>
                </p>
              </div>
            </MotionDiv>
          )}
        </div>
      </div>
    </section>
  )
}
