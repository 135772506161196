'use client'

import { AssetImage } from '@/components/AssetImage/AssetImage'
import { Icon } from '@/components/Icon/Icon'
import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { RichText } from '@/components/RichText/RichText'
import { FeatureProps } from '@/util/payload/types'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { ActionBlock } from '../ActionBlock/ActionBlock'

export const FeatureListItem: FunctionComponent<FeatureProps & { type: string }> = ({ title, description, icon, image, actions, type }) => {
  return (
    <MotionDiv preset='fade-up' className="z-40 rounded-lg border border-white bg-white p-7.5 shadow-solid-3 transition-all hover:shadow-solid-4 dark:border-strokedark dark:bg-blacksection dark:hover:bg-hoverdark xl:p-12.5" >
      {icon && !image && (
        <div className="relative flex h-16 w-16 items-center justify-center rounded-[4px] bg-primary dark:bg-violet-200">
          <Icon icon={icon} className='text-white dark:text-black' width={36} height={36} />
        </div>
      )}
      {image && (
        <AssetImage asset={image} className='w-full h-1/4 rounded-t-lg' />
      )}
      <h3 className={clsx('mb-5 mt-7.5 text-xl font-semibold text-black dark:text-white xl:text-itemtitle', type === 'outreach' && '!text-3xl !font-bold xl:!text-hero text-center')}>
        {title}
      </h3>
      <RichText content={description} />
      {(actions && actions.length > 0) && (
        <div className="mt-6 flex items-center gap-4">
          {actions.map((props) => <ActionBlock key={props.id} {...props} />)}
        </div>
      )}
    </MotionDiv>
  )
}
