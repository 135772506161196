import { FunctionComponent } from 'react'
import PhoneInput from 'react-phone-input-2'
import { FormContactUs } from './ContactSectionBlock'

export interface QuestionFormProps {
  handleChange: (key: keyof FormContactUs, value: string) => void
  message: string
  error: string
  state: FormContactUs
  type: string
}

export const QuestionForm: FunctionComponent<QuestionFormProps> = ({ handleChange, state, message, error, type }) => {
  return (
    <>
      <div className="mb-7.5 flex flex-col gap-7.5 lg:flex-row lg:justify-between lg:gap-14">
        <input
          type="text"
          placeholder="Name"
          className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white lg:w-1/2"
          onChange={(e) => { handleChange('name', e.target.value) }}
          value={state.name}
        />
      </div>

      <div className="mb-12.5 flex flex-col gap-7.5 lg:flex-row lg:justify-between lg:gap-14">
        <input
          type="email"
          placeholder="Email"
          className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white lg:w-1/2"
          onChange={(e) => { handleChange('email', e.target.value) }}
          value={state.email}
        />
        <PhoneInput
          containerClass='!w-full lg:!w-1/2 h-full'
          disableDropdown
          dropdownClass='!bg-white dark:!bg-black !border-t-0 !border-r-0 !border-l-0 !rounded-none !border-b !border-stroke focus:!border-waterloo focus:!placeholder:text-black focus-visible:!outline-none dark:!border-strokedark dark:!focus:border-manatee dark:!focus:placeholder:text-white'
          inputClass='!w-full !bg-transparent !border-t-0 !border-r-0 !border-l-0 !rounded-none !border-b !border-stroke focus:!border-waterloo focus:!placeholder:text-black focus-visible:!outline-none dark:!border-strokedark dark:!focus:border-manatee dark:!focus:placeholder:text-white'
          buttonClass='!bg-transparent hover:!bg-transparent !border-t-0 !border-r-0 !border-l-0 !rounded-none !border-b !border-stroke focus:!border-waterloo focus:!placeholder:text-black focus-visible:!outline-none dark:!border-strokedark dark:!focus:border-manatee dark:!focus:placeholder:text-white'
          onChange={(e) => { handleChange('phone', e) }}
          placeholder='Phone'
          country="sg"
          value={state.phone}
        />
      </div>

      <div className="mb-12.5 flex flex-col gap-7.5 lg:flex-row lg:justify-between lg:gap-14">
        <input
          type="text"
          placeholder="Company Name"
          className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white lg:w-1/2"
          onChange={(e) => { handleChange('company', e.target.value) }}
          value={state.company}
        />
        <input
          type="text"
          placeholder="Industry"
          className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white lg:w-1/2"
          onChange={(e) => { handleChange('industry', e.target.value) }}
          value={state.industry}
        />
      </div>
      <div className="mb-11.5 flex">
        {type === 'standard' && (
          <textarea
            placeholder="What are the biggest challenges in your business now?"
            rows={4}
            className="w-full border-b border-stroke bg-transparent focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white"
            onChange={(e) => { handleChange('challenges', e.target.value) }}
            value={state.challenges}
          ></textarea>
        )}
        {type === 'outreach' && (
          <input
            type="text"
            placeholder="How many sales staff do you have?"
            className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white"
            onChange={(e) => { handleChange('sales', e.target.value) }}
            value={state.sales}
          />
        )}
      </div>
      <div className="mb-11.5 flex">
        {type === 'standard' && (
          <textarea
            placeholder="Describe your productivity goals"
            rows={4}
            className="w-full border-b border-stroke bg-transparent focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white"
            onChange={(e) => { handleChange('goals', e.target.value) }}
            value={state.goals}
          ></textarea>
        )}
        {type === 'outreach' && (
          <input
            type="text"
            placeholder="How many new leads do you want to connect with weekly?"
            className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none dark:border-strokedark dark:focus:border-manatee dark:focus:placeholder:text-white"
            onChange={(e) => { handleChange('leads', e.target.value) }}
            value={state.leads}
          />
        )}
      </div>

      <div className={`mb-11.5 transition-all duration-300 ease-in-out transform ${message ? 'opacity-100 max-h-full' : 'opacity-0 max-h-0'}`}>
        <p className='text-meta'>{message}</p>
      </div>
      <div className={`mb-11.5 transition-all duration-300 ease-in-out transform ${error ? 'opacity-100 max-h-full' : 'opacity-0 max-h-0'}`}>
        <p className='text-red-500'>{error}</p>
      </div>
    </>
  )
}